import React from 'react'
import { css } from 'emotion'
import { animated, useSpring, interpolate, config } from 'react-spring/hooks'
import useWindowSize from './useWindowSize'
import useTouchDetection from './useTouchDetection'
import { mix, progress } from './utils'

export default React.memo(({ media: mediaToLoad, setNextMedia, disabled }) => {
  const media = usePreloadedMedia(mediaToLoad)
  const ref = React.useRef()
  const isCrazy = React.useRef(false)
  const isTouch = useTouchDetection()
  const windowSize = useWindowSize()
  if (!media) return null

  const [targetOrientation, setTargetOrientation] = useSpring(() => ({
    from: { alpha: 0, beta: 0, gamma: 0 },
    config: { friction: 300 },
  }))

  const defaultStyles = {
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    scale: 1,
    x: 0,
    y: 0,
  }

  const styles = disabled ? defaultStyles : {}

  const [style, set] = useSpring(() => ({
    from: defaultStyles,
    ...styles,
    config: {
      tension: 400,
      friction: 50,
    },
  }))

  React.useEffect(() => {
    const listener = event => {
      const tweenOffsetY = (event.clientX / windowSize.width) * 2 - 1
      const tweenOffsetX = (event.clientY / windowSize.height) * 2 - 1

      if (!isTouch && !disabled) {
        set({
          rotateX: mix(0, 15, Math.sin(tweenOffsetX)),
          rotateY: mix(0, -15, Math.sin(tweenOffsetY)),
        })
      }
    }
    window.addEventListener('mousemove', listener)
    return () => window.removeEventListener('mousemove', listener)
  })

  React.useEffect(() => {
    const listener = ({ acceleration, rotationRate, ...rest }) => {
      if (acceleration.z === null) return null
      const craycray = acceleration.z > 5

      if (craycray && !isCrazy.current) {
        // setNextMedia()
      }

      isCrazy.current = craycray

      set({
        scale: Math.abs(acceleration.z) > 2 ? progress(-60, 0, acceleration.z) : 1,
        config: config.wobbly,
        // y: Math.abs(acceleration.y) > 2 ? acceleration.y * -20 : 0,
        // x: Math.abs(acceleration.x) > 2 ? acceleration.x * 20 : 0,
        // rotateX: Math.abs(rotationRate.alpha) > 100 ? rotationRate.alpha / 5 : 0,
        // rotateY: Math.abs(rotationRate.beta) > 100 ? rotationRate.beta / -5 : 0,
        // rotateZ: Math.abs(rotationRate.gamma) > 100 ? rotationRate.gamma / 5 : 0,
      })
    }
    window.addEventListener('devicemotion', listener)
    return () => window.removeEventListener('devicemotion', listener)
  })

  React.useEffect(() => {
    const listener = orientation => {
      let { gamma, beta } = orientation
      if (gamma === null) return null

      setTargetOrientation({ gamma, beta })
      gamma -= targetOrientation.gamma.value
      beta -= targetOrientation.beta.value

      set({
        x: gamma * 1,
        y: beta * 1,
        rotateY: gamma / -3,
        rotateX: beta / 5,
        config: config.wobbly,
      })
    }
    window.addEventListener('deviceorientation', listener)
    return () => window.removeEventListener('deviceorientation', listener)
  })

  const padding = windowSize.width > 350 ? 20 : 30
  const width = Math.min(media.width, windowSize.width - padding * 2)

  return (
    <animated.div
      ref={ref}
      style={{
        position: 'relative',
        width,
        paddingBottom: (media.height / media.width) * 100 + '%',
      }}
      className={css`
        perspective: 100vw;
        max-width: 90vh;
        will-change: padding-bottom, width;
        cursor: none;
        transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      `}
    >
      <animated.div
        style={{
          borderRadius: style.borderRadius,
          transform: interpolate(
            [style.x, style.y, style.scale, style.rotateX, style.rotateY, style.rotateZ],
            (x, y, scale, rotateX, rotateY, rotateZ) => `
              translate3d(${x}px, ${y}px, 0)
              scale(${scale})
              rotateX(${rotateX}deg)
              rotateY(${rotateY}deg)
              rotateZ(${rotateZ}deg)
            `,
          ),
        }}
        className={css`
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}
      >
        <Inner media={media} />
      </animated.div>
    </animated.div>
  )
})

const Inner = ({ media }) => {
  const fill = css`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  `
  switch (media.type) {
    case 'image':
      return (
        <div
          className={css`
            ${fill};
            background-image: url('${media.src}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />
      )
    case 'video':
      return <video muted autoPlay loop src={media.src} className={fill} />
    case 'site':
      return (
        <iframe
          src={media.url}
          className={css`
            ${fill};
            border: none;
          `}
          title="suhsh"
        />
      )
    default:
      return null
  }
}

const usePreloadedMedia = media => {
  const [preloadedMedia, setMedia] = React.useState()
  React.useEffect(() => {
    switch (media.type) {
      case 'image':
        const image = new Image()
        image.src = media.src
        image.onload = () => {
          setMedia({
            ...media,
            width: image.width,
            height: image.height,
          })
        }
        return () => (image.onload = null)
      case 'video':
        const video = document.createElement('video')
        video.src = media.src
        video.oncanplay = () =>
          setMedia({ ...media, width: video.videoWidth, height: video.videoHeight })
        return () => (video.oncanplay = null)
      case 'site':
        setMedia(media)
        return null
      default:
        return null
    }
  }, [media.src])

  return preloadedMedia
}
