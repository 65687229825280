import React from 'react'
import { css } from 'emotion'
import SignupForm from './SignupForm'
import Media from './Media'

export default () => {
  const [showSignup, setShowSignup] = React.useState(window.location.search.includes('signup'))

  return (
    <>
      <Media />
      <Details />
      <SignupButton onClick={() => setShowSignup(true)} />
      {showSignup && <SignupForm />}
    </>
  )
}

const Details = () => (
  <div
    className={css`
      position: absolute;
      left: 10%;
      top: 13vh;
      font-size: 34px;
      line-height: 59px;

      pointer-events: none;

      @media (max-width: 900px) {
        top: 70px;
        font-size: 30px;
        line-height: 50px;
      }

      @media (max-width: 600px) {
        left: 10vw;
        top: 50px;
        margin-right: 0;
        font-size: 20px;
        line-height: 34px;
      }
    `}
  >
    Unfolds innflytningsfest
    <br />
    <time dateTime="2019-03-08T16:00:00.000Z">8. mars fra 17:00</time>
    <br />
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.google.no/maps/place/%C3%98stre+Elvebakke+7,+0182+Oslo"
      className={css`
        font: inherit;
        color: inherit;
        text-decoration: none;
        pointer-events: auto;
        &:hover {
          text-decoration: underline;
        }
      `}
    >
      Østre Elvebakke 7
    </a>
  </div>
)

const SignupButton = props => {
  const [hover, setHover] = React.useState()

  React.useLayoutEffect(() => {
    document.body.style.filter = hover ? 'invert(1)' : ''
    return () => (document.body.style.filter = '')
  }, [hover])

  return (
    <button
      {...props}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseUp={() => setHover(false)}
      className={css`
        font: inherit;
        background: none;
        color: inherit;
        padding: 0;
        border-radius: 0;
        border: none;

        user-select: none;
        position: relative;
        font-size: 34px;
        background: black;
        color: #fdfdfd;
        white-space: nowrap;
        padding: 0.5em 1.3em;

        cursor: pointer;
        position: absolute;
        bottom: 10%;
        right: 10%;

        &:focus {
          outline: 0;
        }

        @media (max-width: 600px) {
          font-size: 22px;
          bottom: 50px;
          right: 50px;
        }

        @media (max-width: 460px) {
          font-size: 18px;
          bottom: 30px;
          right: 30px;
        }
      `}
    >
      Jeg vil på fest!
    </button>
  )
}
