/*
  Value in range from progress
  Given a lower limit and an upper limit, we return the value within
  that range as expressed by progress (usually a number from 0 to 1)
  So progress = 0.5 would change
  from -------- to
  to
  from ---- to
  E.g. from = 10, to = 20, progress = 0.5 => 15
  @param [number]: Lower limit of range
  @param [number]: Upper limit of range
  @param [number]: The progress between lower and upper limits expressed 0-1
  @return [number]: Value as calculated from progress within range (not limited within range)
*/
export const mix = (from, to, progress) => -progress * from + progress * to + from

/*
  Progress within given range
  Given a lower limit and an upper limit, we return the progress
  (expressed as a number 0-1) represented by the given value, and
  limit that progress to within 0-1.
  @param [number]: Lower limit
  @param [number]: Upper limit
  @param [number]: Value to find progress within given range
  @return [number]: Progress of value within range as expressed 0-1
*/
export const progress = (from, to, value) => {
  const toFromDifference = to - from

  return toFromDifference === 0 ? 1 : (value - from) / toFromDifference
}

/*
  Clamp
*/
export const clamp = (min, max, v) => Math.min(Math.max(v, min), max)
