import React from 'react'
import { useSpring, interpolate } from 'react-spring/hooks'
import useTouchDetection from './useTouchDetection'
import { clamp } from './utils'

const easeIn = t => Math.pow(t, 2)

const useFollowMouse = anchor => {
  const previousMousePosition = React.useRef()
  const isTouch = useTouchDetection()

  const [styles, set] = useSpring(() => ({
    from: { x: 0, y: 0 },
    config: { tension: 400, friction: 60 },
  }))

  const updatePosition = force => {
    if (previousMousePosition.current) {
      let { x, y } = previousMousePosition.current
      const yWithScroll = y + window.pageYOffset
      const tweenFromRest = clamp(0, 1, yWithScroll)
      const easedTween = easeIn(tweenFromRest)
      x = easedTween * x
      y = window.pageYOffset + easedTween * (y - window.pageYOffset)

      set({
        immediate: force ? key => key === 'y' && easedTween !== 1 : false,
        y,
        x,
      })
    } else {
      set({
        x: anchor ? anchor.x : window.innerWidth * (window.innerWidth > 600 ? 0.6 : 0.5),
        y: anchor ? anchor.y : window.innerHeight * 0.5,
        immediate: true,
      })
    }
  }

  React.useEffect(() => {
    const listener = event => {
      updatePosition()
    }
    window.addEventListener('touchstart', listener)
    return () => window.removeEventListener('touchstart', listener)
  })

  React.useEffect(() => {
    const listener = event => {
      if (!isTouch) {
        previousMousePosition.current = { x: event.clientX, y: event.clientY }
        updatePosition()
      }
    }
    window.addEventListener('mousemove', listener)
    return () => window.removeEventListener('mousemove', listener)
  })

  React.useEffect(() => {
    const listener = () => updatePosition(true)
    document.addEventListener('scroll', listener, { passive: true })
    return () => document.removeEventListener('scroll', listener)
  })

  React.useEffect(updatePosition)

  return {
    position: 'fixed',
    visibility: styles.y.interpolate(y => (y ? 'visible' : 'hidden')),
    left: 0,
    top: 0,
    transform: interpolate(
      [styles.x, styles.y],
      (x, y) => `translate3d(-50%, -50%, 0) translate3d(${x}px, ${y}px, 0)`,
    ),
  }
}

export default useFollowMouse
