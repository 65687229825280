import React from 'react'
import ReactDOM from 'react-dom'
import { css, injectGlobal } from 'emotion'
import Div100Vh from 'react-div-100vh'
import 'react-app-polyfill/ie11'
import App from './App'

console.log(
  `%c
Well,
⊂_ヽ
　 ＼＼  well,
　　 ＼( ͡° ͜ʖ ͡°)
　　　 >　⌒ヽ
　　　/ 　 へ＼
　　 /　　/　＼＼well.
　　 ﾚ　ノ　　 ヽ_つ
　　/　/
　 /　/|
　(　(ヽ
　|　|、＼
　| 丿 ＼⌒) Det er ikke her festen er!
　| |　　) /
ノ )　　Lﾉ
(_／
`,
  'font-family: arial; font-size: 20px; color: #ff4a2e;',
)

console.log(
  '%cMen, når du først er her og kikker: vi leter etter nye folk som vil være med å lage spennende brukeropplevelser for noen av landets største selskaper og mest spennende startups. Ta en titt på http://jobs.unfold.no. Slå gjerne av en prat på festen, eller så håper vi å høre fra deg!',
  'font-family: "Px Grotesk"; font-size: 22px; color: #111; line-height: 1.4;',
)

injectGlobal`
  html,
  body {
    margin: 0;
    overflow: hidden;
    position: fixed;
  }

  #root {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`

export const getFontFaceDeclaration = () =>
  [['Light', 300], ['Regular', 400], ['Bold', 700]].reduce(
    (decleration, [name, weight]) =>
      (decleration += `
      @font-face {
        font-family: Px Grotesk;
        font-display: auto;
        src: url("/Px Grotesk/Px Grotesk ${name}.woff") format("woff"),
             url("/Px Grotesk/Px Grotesk ${name}.ttf") format("truetype");
        font-weight: ${weight};
        font-style: normal;
      }
      @font-face {
        font-family: Px Grotesk;
        font-display: auto;
        src: url("/Px Grotesk/Px Grotesk ${name} Italic.woff") format("woff"),
            url("/Px Grotesk/Px Grotesk ${name} Italic.ttf") format("truetype");
        font-weight: ${weight};
        font-style: italic;
      }
    `),
    '',
  ) +
  `
  @font-face {
    font-family: Px Grotesk Screen;
    font-display: auto;
    src: url("/Px Grotesk/Px Grotesk Screen.woff") format("woff"),
         url("/Px Grotesk/Px Grotesk Screen.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  `

ReactDOM.render(
  <Div100Vh
    className={css`
      ${getFontFaceDeclaration()}
      font-family: Px Grotesk;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      color: #1c1c1c;
      overflow: auto;
      background: white;
      position: relative;
    `}
  >
    <App />
  </Div100Vh>,
  document.getElementById('root'),
)
