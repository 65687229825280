import React from 'react'
import useWindowSize from './useWindowSize'
import useTouchDetection from './useTouchDetection'
import { clamp } from './utils'

const progressions = []

const useMediaset = mediaset => {
  const currentIndex = React.useRef()
  const [media, setMedia] = React.useState(mediaset && mediaset[0])
  const windowSize = useWindowSize()
  const isTouch = useTouchDetection()
  const mousePosition = React.useRef(window.innerWidth * 0.6)

  const getMediaByIndex = index => {
    if (Array.isArray(mediaset[index])) {
      let progression = progressions.find(p => p.mediaset === mediaset[index])
      if (!progression) {
        progression = { mediaset: mediaset[index], index: 0 }
        progressions.push(progression)
      }

      if (currentIndex.current !== index) {
        progression.index = (progression.index + 1) % progression.mediaset.length
      }

      currentIndex.current = index
      return progression.mediaset[progression.index]
    }

    currentIndex.current = index
    return mediaset[index]
  }

  const getMediaByTween = tween => {
    const index = clamp(0, mediaset.length - 1, Math.floor(mediaset.length * tween))
    return getMediaByIndex(index)
  }

  const getNextMedia = mediaset => {
    if (!mediaset) return null
    const mousePos = Math.min(mousePosition.current, windowSize.width)
    return getMediaByTween((mousePos - 1) / windowSize.width)
  }

  const toggleNextMedia = () => {
    if (!mediaset) return null
    setMedia(getMediaByIndex((currentIndex.current + 1) % mediaset.length))
  }

  React.useEffect(() => {
    const updateMedia = () => {
      const nextMedia = getNextMedia(mediaset)
      if (nextMedia !== media) setMedia(nextMedia)
    }
    const listener = event => {
      mousePosition.current = event.clientX
      updateMedia()
    }
    if (!isTouch) {
      window.addEventListener('mousemove', listener)
    }
    return () => window.removeEventListener('mousemove', listener)
  }, [mediaset, media, windowSize.width])

  React.useEffect(() => void setMedia(getNextMedia(mediaset)), [mediaset])

  return [media, toggleNextMedia]
}

export default useMediaset
