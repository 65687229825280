import React from 'react'

const isBrowser = typeof window !== 'undefined'

const useWindowSize = ({ debounce: delay = 400 } = {}) => {
  const [state, setState] = React.useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0,
  })

  React.useEffect(() => {
    let timeout

    const handler = () => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    const debouncedHandler = () => {
      clearTimeout(timeout)
      timeout = setTimeout(handler, delay)
    }

    const listener = delay ? debouncedHandler : handler

    window.addEventListener('resize', listener)
    return () => {
      clearTimeout(timeout)
      window.removeEventListener('resize', listener)
    }
  }, [])

  return state
}

export default useWindowSize
