import React from 'react'
import { css } from 'emotion'
import { animated } from 'react-spring/hooks'
import { Paragraph, Container } from './SignupForm'
import useFollowMouse from './useFollowMouse'
import MediaView from './MediaView'

export default ({ company }) => {
  const style = useFollowMouse({ x: window.innerWidth * 0.8, y: window.innerHeight * 0.65 })

  if (!window.location.search.includes('signuped')) {
    window.history.pushState({}, document.title, '/')
  }

  return (
    <Container>
      <Paragraph>
        <strong>Veldig bra!</strong>
      </Paragraph>
      <Paragraph>
        Vi har satt deg på lista. Det er plass til mange, men vi anbefaler å komme tidlig i tilfelle
        det blir stor pågang.
      </Paragraph>
      <Paragraph>
        Fest er best med folk man kjenner, så del gjerne siden med noen
        {company ? ` flere fra ${company}, eller med andre` : ''} venner. Og,&nbsp;sett av datoen{' '}
        <a href="/ical.ics">i&nbsp;kalenderen</a>. Sees 8 mars!
      </Paragraph>
      <animated.div
        style={style}
        className={css`
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          pointer-events: none;
        `}
      >
        <MediaView
          media={{ type: 'image', width: 1000, height: 1000, src: '/media/party-party.gif' }}
        />
      </animated.div>
    </Container>
  )
}
