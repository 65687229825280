import { animated } from 'react-spring/hooks'
import React from 'react'
import { css } from 'emotion'
import MediaView from './MediaView'
import useFollowMouse from './useFollowMouse'
import useMediaset from './useMediaset'
import useTouchDetection from './useTouchDetection'

const shuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const mediaset = [
  shuffle([
    '/media/partygifs/1.gif',
    '/media/partygifs/3.gif',
    '/media/partygifs/4.gif',
    '/media/partygifs/6.gif',
    '/media/partygifs/7.gif',
    '/media/partygifs/8.gif',
    '/media/partygifs/9.gif',
    '/media/partygifs/10.gif',
    '/media/partygifs/2.gif',
    '/media/partygifs/11.gif',
    '/media/partygifs/12.gif',
    '/media/partygifs/13.gif',
    '/media/partygifs/14.gif',
    '/media/partygifs/15.gif',
    '/media/partygifs/16.gif',
    'https://i.giphy.com/media/yyVZlxrfwnRao/giphy.gif',
    'https://i.giphy.com/media/10hO3rDNqqg2Xe/giphy.gif',
    'https://i.giphy.com/media/blSTtZehjAZ8I/giphy.gif',
    'https://i.giphy.com/media/13hxeOYjoTWtK8/giphy.gif',
    'https://i.giphy.com/media/fpbqdKRPRkd56/giphy.gif',
    'https://i.giphy.com/media/dTxOCCvQOhRXa/giphy.gif',
    'https://i.giphy.com/media/RwkbF5fbgekGk/giphy.gif',
    'https://i.giphy.com/media/nix6Xt7ZC3Pyg/giphy.gif',
    'https://i.giphy.com/media/xT1XGXgtj8PWdvCLFS/giphy.gif',
    'https://i.giphy.com/media/z3PF0JAN45YU8/giphy.gif',
    'https://i.giphy.com/media/cO2jgCbB11e6I/giphy.gif',
  ]),
  '/media/BOOOOOZE.gif',
  '/media/koso-club.gif',
  '/media/NEW.gif',
  '/media/flashing-sign.gif',
  '/media/More.gif',
]

function useInterval(callback, delay) {
  const savedCallback = React.useRef()

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  })

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const Media = () => {
  const styles = useFollowMouse()
  const [media, toggleMedia] = useMediaset(mediaset)
  const isTouch = useTouchDetection()

  useInterval(() => {
    if (isTouch) toggleMedia()
  }, 3500)

  return (
    <animated.div
      style={styles}
      className={css`
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        pointer-events: none;
      `}
    >
      <MediaView media={{ type: 'image', width: 100, height: 100, src: media }} />
    </animated.div>
  )
}

export default Media
