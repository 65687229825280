import React from 'react'
import { css } from 'emotion'
import InputAutosize from 'react-input-autosize'
import queryString from 'query-string'
import Receipt from './Receipt'

const SIGNUP_API_URL = 'https://us-central1-innflytningsfest.cloudfunctions.net/signup'

export default () => {
  const query = queryString.parse(window.location.search)
  const hasCompleteQuery = query.name && query.name.includes(' ') && query.company && query.email
  const [loading, setLoading] = React.useState(false)
  const [hasSignedUp, setHasSignedUp] = React.useState(false)
  const [name, setName] = React.useState(hasCompleteQuery ? query.name : '')
  const [company, setCompany] = React.useState(hasCompleteQuery ? query.company : '')
  const [email, setEmail] = React.useState(hasCompleteQuery ? query.email : '')
  const [errorOnLabel, setErrorOnLabel] = React.useState(null)
  const resetValidation = React.useCallback(() => setErrorOnLabel(null))

  React.useEffect(resetValidation, [name, email, company])

  const onSubmit = React.useCallback(async event => {
    event.preventDefault()
    const source = window.location.pathname.substr(1)

    if (!name || !name.includes(' ')) return setErrorOnLabel('name')
    if (!email || !email.includes('@')) return setErrorOnLabel('email')

    try {
      const timeout = setTimeout(() => setLoading(true), 300)
      await fetch(
        `${SIGNUP_API_URL}?name=${name}&email=${email}&company=${company}&source=${source}`,
      )
      clearTimeout(timeout)
      setLoading(false)
      setHasSignedUp(true)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setErrorOnLabel('critical-error')
    }
  })

  if (hasSignedUp || window.location.search.includes('signuped')) {
    return <Receipt company={company} />
  }

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <Paragraph largeLineHeight>
          Kult! Selveste{' '}
          <Input
            validationError={errorOnLabel === 'name'}
            label="Fullt navn"
            name="name"
            autoFocus={!name}
            value={name}
            onValue={setName}
          />{' '}
          skal på Unfolds innflytningsfest. Vi&nbsp;gleder oss til å få en fra{' '}
          <Input
            width="3em"
            name="company"
            label="Firma/skole"
            validationError={errorOnLabel === 'company'}
            value={company}
            onValue={setCompany}
          />{' '}
          på&nbsp;besøk.
        </Paragraph>
        <Paragraph largeLineHeight>
          I tilfelle vi trenger å sende ut informasjon om eventet, når vi deg på{' '}
          <Input
            label="E-post"
            name="email"
            type="email"
            value={email}
            validationError={errorOnLabel === 'email'}
            onValue={setEmail}
          />
          . Vi&nbsp;kommer ikke til å bruke e&#8209;posten din på noen&nbsp;annen måte.
        </Paragraph>

        {errorOnLabel ? (
          <ValidationMessage>{getValidationMessageByType(errorOnLabel)}</ValidationMessage>
        ) : (
          <SubmitButton disabled={loading}>
            {loading ? 'Jobber med saken…' : 'Sett meg på lista'}
          </SubmitButton>
        )}
      </form>
    </Container>
  )
}

export const Container = ({ children }) => (
  <div
    className={css`
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      overflow: hidden;
      background: black;
      color: #fdfdfd;
    `}
  >
    <div
      className={css`
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 13vh;

        @media (max-width: 900px) {
          margin-top: 50px;
          margin-left: 5vw;
          margin-right: 5vw;
        }
      `}
    >
      {children}
    </div>
  </div>
)

export const Paragraph = ({ largeLineHeight, ...rest }) => (
  <div
    {...rest}
    className={css`
      font-size: 34px;
      line-height: ${largeLineHeight ? 65 : 55}px;
      max-width: 1000px;
      margin-bottom: 1em;

      a {
        color: inherit;
        font-style: italic;
      }

      @media (max-width: 900px) {
        font-size: 30px;
        line-height: ${largeLineHeight ? 55 : 45}px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
        line-height: 40px;
        line-height: ${largeLineHeight ? 40 : 30}px;
      }
    `}
  />
)

const getValidationMessageByType = type => {
  switch (type) {
    case 'name':
      return 'Fullt navn, pliis.'
    case 'email':
      return 'Oops! Fyll inn e-posten din hvis du vil på festen.'
    default:
      return ''
  }
}

const Input = ({ label, width, value, onValue, validationError, ...rest }) => {
  const [focused, setFocused] = React.useState(false)
  return (
    <label
      className={css`
        position: relative;
      `}
    >
      <span
        className={css`
          position: absolute;
          bottom: 100%;
          left: 0;
          line-height: 1.4;
          font-size: 14px;
          color: #fdfdfd;
          opacity: 0;
          font-weight: 700;
          transform: translate3d(0, 10px, 0);
          transition: 0.2s ease-out;

          ${focused &&
            css`
              transform: none;
              opacity: 1;
            `}
        `}
      >
        {label}
      </span>
      <InputAutosize
        value={value}
        onChange={event => onValue(event.target.value)}
        onFocus={event => setFocused(true)}
        onBlur={event => setFocused(false)}
        {...rest}
        className={css`
          input {
            border: none;
            font: inherit;
            padding: 0;
            line-height: inherit;
            appearance: none;
            box-sizing: border-box;
            background-color: transparent;
            border-radius: 0;
            color: #fdfdfd;

            min-width: ${width || '5em'};
            line-height: 1px;
            transition: min-width 0.5s;
            border-bottom: 2px dashed transparent;
            transition: border-bottom-color 0.2s ease-in-out;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-text-fill-color: #fdfdfd !important;
              transition: background-color 5000s ease-in-out 0s;
            }

            @media (max-width: 600px) {
              border-bottom-width: 1px;
            }

            border-bottom-color: #fdfdfd;
            /* ${!focused &&
              !value &&
              css`
                border-bottom-color: #fdfdfd;
              `} */

            ${validationError &&
              css`
                color: #ff725c;
                ${!value &&
                  css`
                    border-bottom-color: #ff725c;
                  `}
              `}

            &:focus {
              outline: 0;
            }
          }
        `}
      />
    </label>
  )
}

const ValidationMessage = ({ children }) => (
  <div
    className={css`
      color: #ff725c;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 1em;
      svg {
        width: 1em;
        height: 0.9em;
        margin-left: 0.3em;
        position: relative;
        top: -0.2em;
      }
    `}
  >
    {children}
    <svg viewBox="0 0 19 20">
      <g stroke="currentColor" stroke-width="1.4" fill="none" fill-rule="evenodd">
        <path d="M14.175 18.892V2.485M1.662 18.983h12.47" stroke-linecap="square" />
        <path d="M17.635 5.263l-3.677-3.677-3.677 3.677" />
      </g>
    </svg>
  </div>
)

const SubmitButton = props => (
  <button
    className={css`
      font: inherit;
      background: none;
      color: inherit;
      padding: 0;
      border-radius: 0;
      border: none;

      user-select: none;
      font-size: 34px;
      background: white;
      color: black;
      white-space: nowrap;
      padding: 0.5em 1.3em;
      margin-top: 1.7em;
      margin-bottom: 1.7em;

      cursor: pointer;

      &:focus {
        outline: 0;
      }

      @media (max-width: 600px) {
        font-size: 22px;
        bottom: 50px;
        right: 50px;
      }

      @media (max-width: 460px) {
        font-size: 18px;
        bottom: 30px;
        right: 30px;
      }
    `}
    type="submit"
    {...props}
  />
)
